import Grid from "@mui/material/Grid";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";
import FooterSocials from "./FooterSocials";

const FooterPromise = dynamic(() => import("./FooterPromise"), {
    ssr: true,
});

const FooterAccreditation = dynamic(() => import('./FooterAccreditation'), {
    ssr: true,
})

const FooterCompany = dynamic(() => import("./FooterCompany"), {
    ssr: true
});
const FooterContactUs = dynamic(() => import("./FooterContactUs"), {
    ssr: true
});

const FooterInformation = dynamic(() => import("./FooterInformation"), {
    ssr: true
});

const FooterDesktop = () => {

    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const footer_layout_grid = useSelector(state => state.globalReducer.footer_layout_grid);
    const footer_links = useSelector(state => state.globalReducer.footer_links);
    // const gridSize = 12/(Object.keys(footer_links).length + (template_settings['footer_show_contact_us'] == 'yes' ? 1 : 0));
    const gridSize = 12/6;
    
    const {getSetting} = useGlobalSettings();
    const footer_li_class = getSetting('footer_li_class', '');

    const checkFooterElement = (key, type) => {
        switch(key){
            case 'footer_show_company_links':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterCompany className={footer_li_class} links={footer_links.company_links} type={type}/>}
                    </>
                )
            case 'footer_show_company_promises':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterPromise className={footer_li_class} type={type}/>}
                    </>
                )
            case 'footer_show_information_links':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterInformation className={footer_li_class} links={footer_links.information_links} type={type}/>}
                    </>
                )
            case 'footer_show_contact_us':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterContactUs className={footer_li_class} links={footer_links.company_links} type={type} />}
                    </>
            )
            case 'footer_show_review_and_ESSA':
                return (
                    <>
                        <FooterAccreditation className={footer_li_class} type={type} />
                    </>
            )
            case 'footer_show_socials':
                return (
                    template_settings[key] == 'yes' && <>
                        <FooterSocials  />
                    </>
                )
        }
    }

    return (
        <Grid justifyContent={"space-evenly"} spacing={0} className={`boxed-padding`} container>
            {footer_layout_grid && footer_layout_grid.map((element, idx) => (
                <Grid key={idx} lg={gridSize} className="pl-2 pr-2" item>
                    {checkFooterElement(element.footer_key, 'desktop')}
                </Grid>
            ))}            
        </Grid>
    )
}

export default FooterDesktop